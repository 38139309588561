import React, { Component } from 'react';
import { string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';

import * as validators from '../../../util/validators';
import appSettings from '../../../config/settings';
import { availableWorkingHours } from '../../../config/configListing';
import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';

import {
    Form,
    FieldTextInput,
    H4,
    PrimaryButton,
    FieldSelect,
    SecondaryButton,
    FieldCurrencyInput,
    NamedLink,
} from '../../../components';

import requestformLogo from '../../../assets/images/requestform-logo.png';

import css from './ChatForm.module.css';

class ChatFormComponent extends Component {
    constructor(props) {
        super(props);
        this.submittedValues = {};
    }

    render() {
        return (
            <FinalForm
                {...this.props}
                render={fieldRenderProps => {
                    const {
                        handleSubmit,
                        invalid,
                        form,
                        values,
                        intl,
                        name = '',
                        config,
                        showModal,
                        inProgress,
                        currentUserListings,
                        showPortfolio,
                        noToken,
                        selectedHours,
                        inputValues,
                        onTotalTokensChange // new prop for updating totalTokens in parent
                    } = fieldRenderProps;

                    function getInitials(name) {
                        return name.split(' ').map(word => word.charAt(0).toUpperCase()).join('');
                    }
                    const initials = getInitials(name);

                    const parentJobRoles = config.listing.parentJobRoles;
                    if (values.role) {
                        const listing = currentUserListings.find(l => l.id.uuid == values.role);
                        const { roles = [], highestExperience } = (listing && listing.id && listing.attributes.publicData) || {};
                        const totalRoles = parentJobRoles.filter(pjr => roles.map(r => r.label).includes(pjr.label));
                        const totalTokens = highestExperience && highestExperience.tokens
                            ? highestExperience.tokens
                            : (totalRoles.reduce((accu, curr) => curr.tokens + accu, 0) / totalRoles.length);
                        if (inputValues && inputValues.length) {
                            const maxTokens = Math.max(...inputValues.map((data) => data.tokens));
                            const filterData = inputValues.find((data) => data.tokens === maxTokens);
                            form.change('totalTokens', (filterData && filterData.tokens) ? filterData.tokens : totalTokens)
                        } else {
                            form.change('totalTokens', totalTokens);
                        }
                        onTotalTokensChange(totalTokens); // update the parent component's state
                    }

                    // email
                    const emailLabel = intl.formatMessage({
                        id: 'ChatForm.emailLabel',
                    });
                    const emailPlaceholder = intl.formatMessage({
                        id: 'ChatForm.emailPlaceholder',
                    });
                    const emailRequiredMessage = intl.formatMessage({
                        id: 'ChatForm.emailRequired',
                    });
                    const emailRequired = validators.required(emailRequiredMessage);
                    const emailInvalidMessage = intl.formatMessage({
                        id: 'ChatForm.emailInvalid',
                    });
                    const emailValid = validators.emailFormatValid(emailInvalidMessage);

                    const submitDisabled = invalid;

                    return (
                        <Form>
                            <div>
                                {showModal == "Notes"
                                    ? <div className={css.thoughtBox}>
                                        <H4 as="h2" className={css.sectionTitle}>
                                            <div className={css.headingLine}>
                                                <FormattedMessage id="ChatForm.thoughtHeading" />
                                            </div>
                                            <div className={css.headingLine}>
                                                <FormattedMessage id='ChatForm.thoughtHeadingSecond' />
                                            </div>
                                        </H4>
                                        <FieldTextInput
                                            type="textarea"
                                            className={css.textareaBox}
                                            id="thoughts"
                                            name="thoughts"
                                            placeholder={"Write your thoughts here..."}
                                        />
                                        {values.type == 'SEND'
                                            ? <FieldTextInput
                                                type="email"
                                                id={'email'}
                                                name="email"
                                                autoComplete="email"
                                                label={emailLabel}
                                                className={css.textareaBox}
                                                placeholder={emailPlaceholder}
                                                validate={validators.composeValidators(emailRequired, emailValid)}
                                            />
                                            : null}
                                        <div className={css.bottomButton}>
                                            <PrimaryButton
                                                type='button'
                                                inProgress={inProgress == 'SAVE'}
                                                onClick={() => {
                                                    form.change('showModal', showModal);
                                                    form.change('type', 'SAVE');
                                                    handleSubmit(values);
                                                }}
                                            >
                                                <FormattedMessage id="ChatForm.savethoughtNotes" />
                                            </PrimaryButton>
                                            <SecondaryButton
                                                type='button'
                                                inProgress={inProgress == 'SEND'}
                                                onClick={() => {
                                                    if (values.type == 'SEND') {
                                                        handleSubmit(values);
                                                    } else {
                                                        form.change('showModal', showModal);
                                                        form.change('type', 'SEND');
                                                    }
                                                }}
                                            >
                                                <FormattedMessage id="ChatForm.sendNotesButton" />
                                            </SecondaryButton>
                                        </div>
                                    </div>
                                    : <div className={css.chatWrapper}>
                                        <div className={css.sectionContainer}>
                                            <H4 as="h2" className={css.sectionTitle}>
                                                <img src={requestformLogo} />
                                                <FormattedMessage id="ChatForm.Heading" />{showPortfolio ? name : initials}
                                            </H4>
                                            <p className={css.subCenterTitle}><FormattedMessage id="ChatForm.Subheading" /></p>
                                            <div className={css.nameContainer}>
                                                {currentUserListings && currentUserListings.length
                                                    ? <FieldSelect
                                                        id={`role`}
                                                        className={css.quantityField}
                                                        name={"role"}
                                                        label={"Select Role For This Connection"}
                                                        validate={validators.required(intl.formatMessage({ id: 'ChatForm.roleRequired' }))}
                                                    >
                                                        <option disabled value="">Select
                                                        </option>
                                                        {currentUserListings.map((item, i) => (item.attributes.publicData && item.attributes.publicData.roles && item.attributes.publicData.roles.length
                                                            ? <option key={item.attributes.title + '_' + i} value={item.id.uuid}>
                                                                {/* {item.attributes.publicData.roles.map(r => r.label).join(', ')} */}
                                                                {item?.attributes?.title}
                                                            </option>
                                                            : null
                                                        ))}
                                                    </FieldSelect>
                                                    : null}
                                                <div className={css.roleRequest}>
                                                    <NamedLink name="NewCompanyListingPage">+ Create a New Job Listing</NamedLink>
                                                </div>
                                                <div className={css.rowInput}>
                                                    <FieldSelect
                                                        id={`hoursPerWeek`}
                                                        className={css.inputBox}
                                                        name={"hoursPerWeek"}
                                                        label={"Hours per week"}
                                                        validate={validators.required(intl.formatMessage({ id: 'ChatForm.hoursPerWeekRequired' }))}
                                                    >
                                                        <option disabled value="">
                                                            {"Select hours"}
                                                        </option>
                                                        {availableWorkingHours.map((item, i) => (
                                                            <option key={item.label + '-' + i} value={item.value}>
                                                                {item.label}
                                                            </option>
                                                        ))}
                                                    </FieldSelect>

                                                    <FieldCurrencyInput
                                                        id="hourlyPrice"
                                                        name="hourlyPrice"
                                                        className={css.inputBox}
                                                        label={intl.formatMessage({ id: 'ChatForm.hourlyPriceLabel', })}
                                                        placeholder={intl.formatMessage({ id: 'ChatForm.hourlyPricePlaceholder', })}
                                                        currencyConfig={appSettings.getCurrencyFormatting(config.currency)}
                                                        validate={validators.required(intl.formatMessage({ id: 'ChatForm.hourlyPriceRequired', }))}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={css.lastSection}>
                                            <FieldTextInput
                                                type="textarea"
                                                className={css.textareaBox}
                                                id={"bio"}
                                                name={"bio"}
                                                placeholder={`Ask ${showPortfolio ? name : initials} a question she can reply to...`}
                                            />
                                        </div>

                                        <PrimaryButton
                                            className={css.submitButton}
                                            type='button'
                                            onClick={() => {
                                                form.change('experienceToken', (selectedHours && selectedHours.tokens) ? selectedHours.tokens : null)
                                                handleSubmit(values)
                                            }}
                                            inProgress={inProgress == 'SUBMIT'}
                                            disabled={submitDisabled || (currentUserListings && !currentUserListings.length)}
                                        >
                                            Request Connection  {(selectedHours && selectedHours.tokens) ? `${selectedHours.tokens} Token` : (values && values.totalTokens) ? "- " + values.totalTokens + (values.totalTokens == 1 ? ' Token' : ' Tokens')
                                                : null}
                                        </PrimaryButton>
                                        <br />
                                        {(currentUserListings && !currentUserListings.length) ? <div className={css.roleRequests} >*Please create a new role or re-open the listing to request the connection.</div> : null}
                                        {(noToken) ? <div className={css.roleRequests} ><FormattedMessage id="ChatForm.InsufficientTokens" /></div> : null}
                                    </div>}
                            </div>
                        </Form>
                    );
                }}
            />
        );
    }
}

ChatFormComponent.defaultProps = {
    rootClassName: null,
    className: null,
    uploadImageError: null,
    updateProfileError: null,
    updateProfileReady: false,
};

ChatFormComponent.propTypes = {
    rootClassName: string,
    className: string,

    // from injectIntl
    intl: intlShape.isRequired,
};

const ChatForm = compose(injectIntl)(ChatFormComponent);

ChatForm.displayName = 'ChatForm';

export default ChatForm;
